import React from "react";
import Carousel from '@components/carousel';
import { useQuery } from '@apollo/client';
import { SwiperSlide } from 'swiper/react';
import gql from "graphql-tag";
import Loader from '@components/loader';
import {Row, Col, Button} from "react-bootstrap";
import ModuleSection from '@components/modules/moduleWrapper';
import ReviewCard from '@components/modules/Reviews/reviewCard';
import ModuleText from '@components/modules/Text';
import ModuleTitle from '@components/modules/Title';
import { fadeIn } from '@components/utils/animations';

import "../ReviewGridModule/ReviewSlider.scss"


const GET_ALL_REVIEWS = gql`
    query GetReviwes {
        reviews(limit: 10,publicationState: LIVE, sort: "Date:DESC" ,where: {
            Show_Multi_Country_Page: false
          }) {
            id
            Text
            Person
            Date
        }
    }
`;

const ReviewGrid = (props) => {
    const { loading, error, data } = useQuery(GET_ALL_REVIEWS);
    //console.log("data",data?.reviews)
    const sliderConfig = {
        slidesPerView: 1,
        spaceBetween: 32,
        navigation:  true,
        autoplay: true,
        // loop:true,
        breakpoints: {
            // when window width is >= 768px
            768: {
                slidesPerView: 2,
                spaceBetween: 16
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 32
            },
            1200: {
                slidesPerView: 3,
                spaceBetween: 32
            }
        }
    }

    return(
        <ModuleSection
            sectionClass={`customer-reviews section-grey`}>
                {props.ReviewSectionTitle &&
                    <div className="mb-16 mb-md-24 mb-lg-32 module__title" variants={fadeIn}>
                        <ModuleTitle
                            title={props.ReviewSectionTitle}
                            className="mb-16 mb-md-24 mb-lg-32 module__title"
                        />
                    </div>
                }
                {props.ReviewSectionDesc &&
                    <ModuleText
                        text={props.ReviewSectionDesc}
                        className="module__text d-block mb-40 mb-lg-56"
                    />
                }
        <div>
            {/* <h2 className="review-title">{props.ReviewSectionTitle}</h2>
            <p>{props.ReviewSectionDesc}</p> */}
            <Row>
            <Col xs={12}>
                    <Loader loading={loading} error={error}>
                        <Carousel className="customer-reviews-carousel" {...sliderConfig}>
                            {data?.reviews?.map((review, i) => {
                                //console.log("review",review)
                                return(
                                    <SwiperSlide key={review.id}>
                                        <ReviewCard review={review} className="reviews-slider__item" custom={i} />
                                    </SwiperSlide>
                                )
                            })}
                        </Carousel>
                    </Loader>
                </Col>
            </Row>
            
        </div>
         </ModuleSection>
    )
  
}
export default ReviewGrid;