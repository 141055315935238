import * as React from "react";
import { graphql } from "gatsby";
import PropertySearch from '@components/PropertySearch';
import PropertyWorth from "@components/PropertyWorth";
import ModuleSection from '@components/modules/moduleWrapper';
import Stack from "react-bootstrap/Stack";
import { StaticImage } from "gatsby-plugin-image";

import './style.scss';

const SearchCta = ({ moduleData }) => { 
    return(
        <ModuleSection
            sectionClass={`section-primary position-relative search-cta only-mobile ${moduleData?.moduleClass ? moduleData.moduleClass : ""}`}
            removeContainer={moduleData?.removeContainer ? moduleData.removeContainer : false}
            sectionSpacing={moduleData?.sectionSpacing}
        >
            <StaticImage
                src="../../../images/pattern.jpg"
                quality={50}
                formats={["auto", "webp"]}
                alt="Background Image - Exclusive Links"
                className="pattern-img"
            />
            <Stack gap={40} className="vstack search-cta__container position-relative z-index-5">
                <PropertySearch />
                <div className="search-cta__divider"></div>
                <PropertyWorth />
            </Stack>
        </ModuleSection>
    )
}

export default SearchCta

export const propertySearchFragment = graphql`
    fragment PropertySearchFragment on GLSTRAPI_ComponentModulesSearchCta {
        id
        moduleType
        moduleData {
            Text
            Title
            moduleClass
            sectionSpacing
        }
    }
`