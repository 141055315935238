import * as React from "react";
import { graphql } from "gatsby";
import ModuleSection from '@components/modules/moduleWrapper';
import { Col, Row } from "react-bootstrap";
import { StarberryIcons } from "@components/icons/index";
import ButtonLink from '@components/ButtonLink';
import ModuleTitle from '@components/modules/Title';
import ModuleText from '@components/modules/Text';
// import { delayItemsFromBottom, fadeIn } from '@components/utils/animations';

import './iconBox.scss';



const IconBox = ({ moduleData, Items,CountryFlagStatus, ...rest }) => {
    return(
        <ModuleSection
            sectionClass={`search-icon-box ${rest?.moduleClass} ${CountryFlagStatus && "multi_country_icon_box_item"}`}
            sectionSpacing={moduleData?.sectionSpacing}
        >
            <Row className="mb-lg-96">
                {moduleData?.Title &&
                    <Col lg={5}>
                        <div /*variants={fadeIn}*/>
                            <ModuleTitle
                                title={moduleData.Title}
                                className="search-icon-box__title mb-16 mb-md-24 mb-lg-0"
                            />
                        </div>
                    </Col>
                }
                {moduleData?.Text &&
                    <Col lg={{ span: 6, offset: 1 }}>
                        <div /*variants={fadeIn}*/>
                            <ModuleText
                                text={moduleData.Text}
                                className="search-icon-box__text d-block mb-35 mb-md-48 mb-lg-0"
                            />
                        </div>
                    </Col>
                }
            </Row>
            {Items.length > 0 &&
                <Row xs={2} md={3} lg={5} className="g-40 gx-md-68 gy-md-48 gx-lg-60 gy-lg-0">
                    {Items?.map((item, i) => {
                        const buttons = [
                            {
                                manualLink: item.btnManualLink,
                                page: item.btnPage,
                                btnClass: item.btnClass,
                                label: item.btnLabel,
                                icon: item.btnIcon
                            }
                        ]

                        return(
                            <Col className="icon-box-item-home" key={item.id} /*variants={delayItemsFromBottom}*/ custom={i}>
                                {item?.icon &&
                                    <StarberryIcons iconName={item.icon} className="search-icon-box_item__icon d-block ms-0 mb-24 mb-md-32" />
                                }
                                {item?.Title &&
                                    <ModuleTitle
                                        as="h3"
                                        title={item.Title}
                                        className={`search-icon-box_item__title  ${CountryFlagStatus ? "mb-4 mb-md-10" : "mb-8 mb-md-24"}`}
                                    />
                                }
                                {item?.Text &&
                                    <ModuleText
                                        text={item.Text}
                                        className="d-block search-icon-box_item__text"
                                    />
                                }
                                {buttons[0].label &&
                                    <>
                                        {buttons.map((btn, i) => {
                                            const btnChangeClass = {...btn, btnClass: "btn-link-primary-underlined text-start"};
                                            return(
                                                <ButtonLink key={i} {...btnChangeClass} variant="link" />
                                            )
                                        })}
                                    </>
                                }
                            </Col>
                        )
                    })}
                </Row>
            }
        </ModuleSection>
    )
}

export default IconBox

export const iconBoxFragment = graphql`
    fragment IconBoxFragment on GLSTRAPI_ComponentModulesIconBox {
        id
        moduleType
        moduleData {
            Text
            Title
            moduleClass
            sectionSpacing
        }
        Items {
            id
            Title
            Text
            icon
            btnManualLink
            btnClass
            btnLabel
            btnIcon
            btnPage {
                Slug
                id
                Parent {
                    Slug
                }
                Parent2 {
                    Slug
                }
                Parent3 {
                    Slug
                }
            }
        }
    }
`