import React from "react";
import { useStaticQuery, graphql } from 'gatsby'
import ModuleSection from '@components/modules/moduleWrapper';
import { SwiperSlide } from 'swiper/react';
import Carousel from '@components/carousel';
import PodcastCard from '@components/modules/Podcasts/podcastCard';
import ModuleText from '@components/modules/Text';
import ModuleTitle from '@components/modules/Title';
import { StaticImage } from "gatsby-plugin-image";
import Stack from 'react-bootstrap/Stack';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonLink from "@components/ButtonLink"

import './latestPodcasts.scss';

const LatestPodcasts = ({ moduleData }) => {
    const data = useStaticQuery(graphql`
        query {
            glstrapi {
                podcasts(publicationState: LIVE, sort: "createdAt:DESC", limit: 4) {
                    Title
                    Slug
                    id
                    Buzzsprout
                    Episode
                    BannerImage {
                        ... on GLSTRAPI_ComponentModulesBannerImage {
                            Image {
                                url
                                url_sharp {
                                    childImageSharp {
                                        gatsbyImageData(quality: 100)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    const { glstrapi: { podcasts } } = data;

    const sliderConfig = {
        slidesPerView: 1,
        spaceBetween: 32,
        breakpoints: {
            // when window width is >= 768px
            768: {
              slidesPerView: 2,
              spaceBetween: 16
            },
            1200: {
                slidesPerView: 4,
                spaceBetween: 32
            }
        }
    }

    return(
        <ModuleSection
            removeContainer={true}
            sectionClass={`module podcasts-slider swiper-overflow-hidden ${moduleData?.moduleClass}`}
            sectionSpacing={moduleData?.sectionSpacing}
        >
            <Container fluid="lg">
                <Row>
                    <Col xs={12}>
                        {moduleData?.Title &&
                            <div className="mb-16 mb-md-24 mb-lg-32 module__title" /*variants={fadeIn}*/>
                                <ModuleTitle
                                    title={moduleData.Title}
                                    className="mb-16 mb-md-24 mb-lg-32 module__title"
                                />
                            </div>
                        }
                        {moduleData?.Text &&
                            <ModuleText
                                text={moduleData.Text}
                                className="module__text d-block mb-40 mb-lg-56"
                            />
                        }
                    </Col>
                </Row>
            </Container>

            <Container fluid="lg">
                <Row>
                    <Col xs={12}>
                        <Carousel className="podcasts-carousel" {...sliderConfig}>
                            {podcasts?.map((podcast, i) => {
                                return(
                                    <SwiperSlide key={podcast.id}>
                                        <PodcastCard podcast={podcast} className="podcasts-slider__item" custom={i} />
                                    </SwiperSlide>
                                )
                            })}
                        </Carousel>
                    </Col>
                </Row>
            </Container>

            <Container fluid="lg">
                <Row>
                    <div className="podcasts-end-cta">
                    <ButtonLink manualLink="/media/uae-property-podcast/" btnClass="btn btn-primary" label="View More"/>
                    </div>
                </Row>
            </Container>

            <Container fluid="lg">
                <Row>
                    <Col xs={12}>
                        <Stack gap={32} direction="horizontal" className="podcasts-sources mt-20">
                            <a href="https://propertytalkfromlouiseslounge.buzzsprout.com/" className="d-block" target="_blank" rel="noreferrer">
                                <Stack direction="vertical" className="gap-8 gap-md-24 flex-md-row">
                                    <div>
                                        <StaticImage
                                            src="../../../images/buzzsprout.png"
                                            width={152}
                                            height={24}
                                            quality={100}
                                            formats={["auto", "webp"]}
                                            alt="Subscribe to Buzzsprout"
                                        />
                                    </div>
                                    <p>Subscribe to Buzzsprout</p>
                                </Stack>
                            </a>
                            <div className="vr" />
                            <a href="https://www.youtube.com/c/Exclusive-links" className="d-block" target="_blank" rel="noreferrer">
                                <Stack direction="vertical" className="gap-8 gap-md-24 flex-md-row">
                                    <div>
                                        <StaticImage
                                            src="../../../images/youtube.png"
                                            width={92}
                                            quality={19}
                                            formats={["auto", "webp"]}
                                            alt="Subscribe to YouTube"
                                        />
                                    </div>
                                    <p>Subscribe to YouTube</p>
                                </Stack>
                            </a>
                        </Stack>
                    </Col>
                </Row>
            </Container>
        </ModuleSection>
    )
}

export default LatestPodcasts

export const latestPodcastsFragment = graphql`
    fragment LatestPodcastsFragment on GLSTRAPI_ComponentModulesLatestPodcasts {
        id
        moduleType
        moduleData {
            Text
            Title
            moduleClass
            sectionSpacing
        }
    }
`