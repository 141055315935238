import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Stack from "react-bootstrap/Stack"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Helmet } from "react-helmet"
import ButtonLink from "@components/ButtonLink"
import ModuleSection from "@components/modules/moduleWrapper"
import ModuleText from "@components/modules/Text"
import ModuleTitle from "@components/modules/Title"
import Accordion from "@components/accordion"

import { fadeInFromTop } from "@components/utils/animations"

import "./faq.scss"

const AccordionText = ({ text }) => (
    <ModuleText
        text={text}
        // className=""
    />
)

const AccordionItem = ({ data, limit }) => {

  const accordionArray = data?.map((item, i) => {
    if (i + 1 <= limit) {
      return {
        id: `${item?.id}`,
        toggleName: `${item?.Title}`,
        content: <AccordionText text={item.Text} />,
      }
    } else if (limit === 0) {
      return {
        id: `${item?.id}`,
        toggleName: `${item?.Title}`,
        content: <AccordionText text={item.Text} />,
      }
    } else {
      return null
    }
  })

    if (data?.length > 0) {
        return(
            <Accordion flush accordionData={accordionArray.filter(n => n)} className="faq-accordion" />
        )
    } else {
        return null
    }
}

const AccordionItemRender = ({data, limit}) => {
//console.log('schamaaa', data)
 
 
var faqschemaList = [];
  
      data?.map((faqitem,i) =>{
        faqschemaList.push({
            "@type": "Question",
              "name": `${faqitem?.Title}`,
              "acceptedAnswer": {
                "@type": "Answer",
                "text": `${faqitem?.Text}`
              }
        })
      })
  
  return (
    <>
    <Helmet>
      <script type="application/ld+json">
        {`{
                            "@context": "https://schema.org",
                            "@type": "FAQPage",
                            "mainEntity": ${JSON.stringify(
                              faqschemaList,
                              null,
                              2
                            )}
                      }`}
      </script>
    </Helmet>
    <AccordionItem data={data} limit={limit} />
    </>
  )
}

const SwitchFaq = ({ category, data, limit }) => {
  switch (category) {
    case "Buyer":
      return (
         <AccordionItemRender data={data?.glstrapi?.buyer} limit={limit} />
      )
    case "Seller":
      return ( 
        <AccordionItemRender data={data?.glstrapi?.seller} limit={limit} />
      )
    case "Landlord":
      return (
        <AccordionItemRender data={data?.glstrapi?.landlord} limit={limit} />
      )
    case "Commercial":
      return(
        <AccordionItemRender data={data?.glstrapi?.commercial} limit={limit} />
      )
    case "Tenant":
      return (
      <AccordionItemRender data={data?.glstrapi?.tenant} limit={limit} />
      )
    case "Property_Management":
      return (
        <AccordionItemRender data={data?.glstrapi?.propertyManagement} limit={limit}/>
      )
      case "Home":
        return (
        <AccordionItemRender data={data?.glstrapi?.home} limit={limit} />
        )

    case "Owners":
      return (
      <AccordionItemRender data={data?.glstrapi?.owners} limit={limit} />
      )
    case "Guest":
      return (
      <AccordionItemRender data={data?.glstrapi?.guest} limit={limit} />
      )
    case "Off_Plan":
      return (
      <AccordionItemRender data={data?.glstrapi?.offplan} limit={limit} />
      )
    case "Vacation_Homes":
      return (
        <AccordionItemRender data={data?.glstrapi?.vacationHomes} limit={limit} />
      )
      case "Luxury_Property":
        return (
          <AccordionItemRender data={data?.glstrapi?.luxuryProperty} limit={limit} />
        )

    default:
      return null
  }
}

const FAQModule = ({ moduleData, Buttons, limit, page, category }) => {
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        buyer: faqs(
          publicationState: LIVE
          sort: "Order:ASC"
          where: { category: "Buyer" }
        ) {
          id
          Title
          Text
          category
        }
        luxuryProperty: faqs(
          publicationState: LIVE
          sort: "Order:ASC"
          where: { category: "Luxury_Property" }
        ) {
          id
          Title
          Text
          category
        }

        seller: faqs(
          publicationState: LIVE
          sort: "Order:ASC"
          where: { category: "Seller" }
        ) {
          id
          Title
          Text
          category
        }
        landlord: faqs(
          publicationState: LIVE
          sort: "Order:ASC"
          where: { category: "Landlord" }
        ) {
          id
          Title
          Text
          category
        }
        commercial: faqs(
          publicationState: LIVE
          sort: "Order:ASC"
          where: { category: "Commercial" }
        ) {
          id
          Title
          Text
          category
        }
        tenant: faqs(
          publicationState: LIVE
          sort: "Order:ASC"
          where: { category: "Tenant" }
        ) {
          id
          Title
          Text
          category
        }
        home: faqs(
          publicationState: LIVE
          sort: "Order:ASC"
          where: { category: "Home" }
        ) {
          id
          Title
          Text
          category
        }
        propertyManagement: faqs(
          publicationState: LIVE
          sort: "Order:ASC"
          where: { category: "Property_Management" }
        ) {
          id
          Title
          Text
          category
        }
        owners: faqs(
          publicationState: LIVE
          sort: "Order:ASC"
          where: { category: "Owners" }
        ) {
          id
          Title
          Text
          category
        }
        guest: faqs(
          publicationState: LIVE
          sort: "Order:ASC"
          where: { category: "Guest" }
        ) {
          id
          Title
          Text
          category
        }
        offplan: faqs(
          publicationState: LIVE
          sort: "Order:ASC"
          where: { category: "Off_Plan" }
        ) {
          id
          Title
          Text
          category
        }
        vacationHomes: faqs(
          publicationState: LIVE
          sort: "Order:ASC"
          where: { category: "Vacation_Homes" }
        ) {
          id
          Title
          Text
          category
        }
      }
    }
  `)
 
 

  return (
    <ModuleSection
      removeContainer={
        moduleData?.removeContainer ? moduleData.removeContainer : false
      }
      sectionSpacing={moduleData?.sectionSpacing}
      sectionClass={`faq-module ${
        moduleData?.moduleClass ? moduleData.moduleClass : ""
      }`}
    >
      {moduleData?.Title && (
        <Row className="faq-section-module">
          <Col xs={12} lg={4}>
            <div
              className="mb-16 mb-md-24 mb-lg-32"
              variants={fadeInFromTop}
            >
              <ModuleTitle
                as="p"
                title={moduleData.Title}
                className="faq-module__title module__title module__title_bold"
              />
            </div>
          </Col>
        </Row>
      )}
      <Row>
        {moduleData?.Text && (
          <Col xs={12} lg={4} variants={fadeInFromTop}>
            <ModuleText
              text={moduleData.Text}
              className="faq-module__text d-block mt-lg-16"
            />
            {Buttons.length > 0 && (
              <Stack className="gap-8 gap-md-24 mt-16 mb-24 mt-md-32 mb-md-32 mt-lg-40 mb-lg-0 flex-md-row flex-md-wrap">
                {Buttons.map(button => {
                  let addedBtnClass = {
                    ...button,
                    variant: null,
                    btnClass: button?.btnClass
                      ? `${button.btnClass}`
                      : "btn btn-primary",
                  }
                  return <ButtonLink {...addedBtnClass} key={button?.id} />
                })}
              </Stack>
            )}
          </Col>
        )}
        <Col
          xs={12}
          lg={moduleData?.Text ? { span: 7, offset: 1 } : { span: 12 }}
        >
          <SwitchFaq category={category} data={data} limit={limit} />

          {page && (
            <div
              variants={fadeInFromTop}
              className="mt-40 mt-md-48 mt-lg-64"
            >
              <ButtonLink
                page={page}
                label="View more FAQs"
                variant="link-primary-underlined"
              />
            </div>
          )}
        </Col>
      </Row>
    </ModuleSection>
  )
}

export default FAQModule

export const faqFragment = graphql`
  fragment FaqFragment on GLSTRAPI_ComponentModulesFaq {
    id
    moduleType
    category
    limit
    page {
      Slug
      id
      Parent {
        Slug
      }
      Parent2 {
        Slug
      }
      Parent3 {
        Slug
      }
    }
    moduleData {
      Text
      Title
      moduleClass
      sectionSpacing
    }
    Buttons {
      id
      manualLink
      btnClass
      label
      icon
      func
      page {
        Slug
        id
        Parent {
          Slug
        }
        Parent2 {
          Slug
        }
        Parent3 {
          Slug
        }
      }
    }
  }
`
