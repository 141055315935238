import React from "react";
import { useStaticQuery, graphql } from 'gatsby'
import ModuleSection from '@components/modules/moduleWrapper';
import { SwiperSlide } from 'swiper/react';
import Carousel from '@components/carousel';
import AwardCard from '@components/modules/Awards/award';
import ModuleText from '@components/modules/Text';
import ModuleTitle from '@components/modules/Title';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { fadeIn } from '@components/utils/animations';

import './awards.scss';

const AwardsSlider = ({ moduleData }) => {
    const data = useStaticQuery(graphql`
        query {
            glstrapi {
                awards(limit: 6, publicationState: LIVE, sort: "Date:desc") {
                    BannerImage {
                        ... on GLSTRAPI_ComponentModulesBannerImage {
                            id
                            ItemText
                            ItemTitle
                            Image {
                                url
                                alternativeText
                            }
                        }
                    }
                    imagetransforms
                    Date
                    Slug
                    id
                }
            }
        }
    `)

    const { glstrapi: { awards } } = data;

    const sliderConfig = {
        slidesPerView: 1,
        spaceBetween: 32,
        autoplay: true,
        breakpoints: {
            // when window width is >= 768px
            768: {
              slidesPerView: 2,
              spaceBetween: 16
            },
            992: {
              slidesPerView: 4,
              spaceBetween: 32
            }
        }
    }

    return(
        <ModuleSection
            removeContainer={true}
            sectionClass={`module awards-slider swiper-overflow-hidden ${moduleData?.moduleClass}`}
            sectionSpacing={moduleData?.sectionSpacing}
        >
            <Container fluid="lg">
                <Row>
                    <Col xs={12}>
                        {moduleData?.Title &&
                            <div className="mb-16 mb-md-24 mb-lg-32 module__title" variants={fadeIn}>
                                <ModuleTitle
                                    title={moduleData.Title}
                                    className="mb-16 mb-md-24 mb-lg-32 module__title"
                                />
                            </div>
                        }
                        {moduleData?.Text &&
                            <ModuleText
                                text={moduleData.Text}
                                className="module__text d-block mb-40 mb-lg-56"
                            />
                        }
                    </Col>
                </Row>
            </Container>

            <Container fluid="lg">
                <Row>
                    <Col xs={12}>
                        <Carousel className="awards-carousel" {...sliderConfig}>
                            {awards?.map((award, i) => {
                                return(
                                    <SwiperSlide key={award.id}>
                                        <AwardCard award={award} className="awards-slider__item" custom={i} />
                                    </SwiperSlide>
                                )
                            })}
                        </Carousel>
                    </Col>
                </Row>
            </Container>
        </ModuleSection>
    )
}

export default AwardsSlider

export const awardsSliderFragment = graphql`
    fragment AwardsSliderFragment on GLSTRAPI_ComponentModulesAwards {
        id
        moduleType
        moduleData {
            Text
            Title
            moduleClass
            sectionSpacing
        }
    }
`