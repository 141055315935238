import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import ModuleSection from '@components/modules/moduleWrapper';
import ModuleTitle from '@components/modules/Title';
import ModuleText from '@components/modules/Text';
import Stack from "react-bootstrap/Stack";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonLink from '@components/ButtonLink';
import GoogleReviews from '@components/googleReviews';
import Image from "@components/GatsbyImage/gatsbyImage";
import Google_Cta_Banner from "../../../../static/images/google_cta_banner.webp";

import './googleCta.scss';

const GoogleCta = ({ moduleData, Button, image }) => {
    let staticReview = "";
    let staticTitle = "";
    const Google_review = useStaticQuery(graphql`
            query {
                glstrapi {
                    siteConfig {
                      Google_Review_Static
                      Google_Review_Title
                    }
                  }
            }
        `
    )
    const googleReview = Google_review && Google_review?.glstrapi && Google_review?.glstrapi.siteConfig;
    if(googleReview){
        staticTitle = googleReview?.Google_Review_Title;
        staticReview = googleReview?.Google_Review_Static;
    }
    const dataDefault = {
        Title: staticTitle, // "We are your go to <strong>Dubai Real Estate Broker</strong>."
        leave_a_review: staticReview
    }
    const btnDefault = [
        { id: "1", label: "Head Office", manualLink: "/contact-exclusive-links/our-head-office" },
        { id: "2", label: "Dubai Marina Office", manualLink: "/contact-exclusive-links/real-estate-agents-in-dubai-marina" },
        { id: "3", label: "Abu Dhabi Office", manualLink: "/contact-exclusive-links/real-estate-agents-in-abu-dhabi" }
    ]
    const moduleDataDefault = dataDefault // moduleData ? moduleData : dataDefault;
    const buttonDataDefault = Button.length > 0 ? Button : btnDefault;

    // const [renderComponent, setRenderComponent] = useState(false);

    // useEffect(() => {
    //   window.addEventListener("mousemove", () => {
    //     if (renderComponent === false) {
    //       setRenderComponent(true)
    //     }
    //     })
    //     window.addEventListener("touchmove", () => {
    //         if (renderComponent === false) {
    //         setRenderComponent(true)
    //         }
    //     })
    //     window.addEventListener("keypress", () => {
    //         if (renderComponent === false) {
    //         setRenderComponent(true)
    //         }
    //     })
    // }, [])
    return(
        <ModuleSection
            sectionClass={`google-cta section-no-py ${moduleData?.moduleClass ? moduleData.moduleClass : ""}`}
            removeContainer={moduleData?.removeContainer ? moduleData.removeContainer : true}
        >
            <div className="container-fullsize">
                {image?.url_sharp ?
                    <Image g_image={image?.url_sharp} alt={"Background Image - Exclusive Links"} className="google-cta__img" s_width={1800} s_height={500} />
                    :
                    <img src={Google_Cta_Banner} alt={"Background Image - Exclusive Links"} className="google-cta__img" s_width={1800} s_height={500} style={{objectFit:"cover"}} />
                }
                <Container fluid="lg">
                    <Row>
                        <Col xs={12}>
                            <div className="google-cta__wrapper py-32 py-md-48 py-lg-160">
                                {moduleDataDefault?.Title &&
                                    <ModuleTitle
                                        title={moduleDataDefault.Title}
                                        className="google-cta__title mb-8 mb-md-16"
                                    />
                                }
                                {moduleDataDefault?.Text &&
                                    <ModuleText
                                        text={moduleDataDefault.Text}
                                        className="google-cta__text mb-24 mb-md-32 mb-lg-40"
                                    />
                                }
                                <GoogleReviews
                                    className="google-cta__text mb-24 mb-md-32 mb-lg-40"
                                    affix=" – get in touch to find out how we can help."
                                    review={moduleDataDefault.leave_a_review}
                                />
                                {buttonDataDefault.length > 0 &&
                                    <Stack className="vstack gap-16 flex-md-row gap-md-24">
                                        {buttonDataDefault.map((btn) => {
                                            return(
                                                <ButtonLink key={btn?.id} {...btn} variant="secondary" />
                                            )
                                        })}
                                    </Stack>
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

        </ModuleSection>
    )
}

export default GoogleCta

export const googleCtaFragment = graphql`
    fragment GoogleCtaFragment on GLSTRAPI_ComponentModulesGoogleCta {
        id
        moduleType
        moduleData {
            Text
            Title
            moduleClass
            leave_a_review  
        }
        image {
            url
            url_sharp {
                childImageSharp {
                    gatsbyImageData(width: 1800, formats: WEBP, quality: 90)
                }
            }
        }
        Button {
            id
            manualLink
            btnClass
            label
            icon
            func
            page {
                Slug
                id
                Parent {
                    Slug
                }
                Parent2 {
                    Slug
                }
                Parent3 {
                    Slug
                }
            }
        }
    }
`