import React, { useEffect, useRef } from "react";
import { animate } from "framer-motion";
import { Stack } from "react-bootstrap";

import './countNumber.scss';

function Counter({ from, to, fraction, prefix, suffix, text }) {
    //console.log("fraction",fraction)
    const ref = useRef();
    const printVal = (value, fraction) =>{
        if(value == 0){
            return '';
        }else{
            return  value.toFixed(fraction ? fraction : 0)
        }
    }
    useEffect(() => {
        const controls = animate(from, to, {
            duration: 1.2,
            // ease: easeFunc,
            onUpdate(value) {
                //console.log("value",value)
                ref.current.innerHTML = `<span class="count-number__number">${(prefix ? prefix : '') + printVal(value, fraction)}${(suffix ? suffix : '')}</span><span class="count-number__text">${text}</span>`;
            }
        });
        return () => controls.stop();
    }, [from, to, fraction, prefix, suffix, text]);
  
    return <Stack ref={ref} className="count-number gap-8 gap-md-16" />;
}

export default function CounterNumber(props) {
    return (
        <Counter {...props} />
    )
}