import React from 'react';
import Accordion from 'react-bootstrap/Accordion';

import { delayItemsFromBottom } from '@components/utils/animations';

const AccordionWrapper = ({ accordionData, ...rest }) => {
    return(
        <Accordion {...rest}>
            {accordionData?.map((item, i) => {
                return(
                    <Accordion.Item variants={delayItemsFromBottom} custom={i} eventKey={i} key={i}>
                        {item?.toggleName && <Accordion.Header id={item.toggleName?.toLowerCase()}>{item.toggleName}</Accordion.Header>}
                        {item?.content && <Accordion.Body>{item.content}</Accordion.Body>}
                    </Accordion.Item>
                )
            })}
        </Accordion>
    )
}

export default AccordionWrapper
