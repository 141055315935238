import React from "react";
import Stack from "react-bootstrap/Stack";
import ModuleTitle from '@components/modules/Title';
import moment from 'moment';
import ButtonLink from '@components/ButtonLink';
import { Routes } from '@components/routes';
import { cleanP } from '@components/utils/CleanedHtml';
import { StaticImage } from "gatsby-plugin-image";
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";

import { delayItemsFromBottom } from '@components/utils/animations';

import './newsCard.scss';

const NewsCard = ({ news, className, custom }) => {
    const image = news?.BannerImage[0]?.Image?.url;
    const image_alt =  cleanP(news?.BannerImage[0]?.ItemTitle);
    // GGFX
    let processedImages = JSON.stringify({});
    if (news?.imagetransforms?.BannerImage_Transforms) {
        processedImages = news?.imagetransforms?.BannerImage_Transforms;
    }

    
    return(
        <div
            className={`${className} h-100`}
            variants={delayItemsFromBottom}
            custom={custom}
        >
            <div
                className="news-card h-100"
            >
                
                <div className="news-card__image__wrapper">
                    <ButtonLink variant="none" manualLink={`${Routes.MediaPage}${news.ShowOnNewsLetterPage ? Routes.NewsPage: Routes.BlogPage}/${news?.Slug}`}>
                        {image ?
                            <ImageTransform
                                imagesources={image}
                                renderer="srcSet"
                                imagename="all-news.BannerImage.slidertile"
                                attr={{ alt: image_alt+" - Exclusive Links", className: "news-card__image" }}
                                imagetransformresult={processedImages}
                                id={news.id}
                            />
                        :
                            <StaticImage
                                src="../../../images/no-image.png"
                                width={440}
                                height={230}
                                formats={["auto", "webp"]}
                                alt=""
                                className="news-card__image"
                            />
                        }
                    </ButtonLink>
                    {/* <div className="news-wrapper">
                        <StarberryIcons iconName="videoIcon" className="bi news-icon" />
                    </div> */}
                </div>

                <Stack direction="vertical" className="gap-16 p-24 p-lg-32 news-card__text">
                    <Stack direction="horizontal" className="gap-16">
                        {news?.tag && !news.ShowOnNewsLetterPage &&
                            <span className="p-10 news-card__tag">{news.tag}</span>
                        }
                         {news?.Issue && news.ShowOnNewsLetterPage &&
                            <span className="p-10 news-card__tag">Issue {news.Issue}</span>
                        }
                        {news?.Date &&
                            <div className="news-card__date">
                                {moment(news.Date).format("D MMM YYYY")}
                            </div>
                        }
                    </Stack>
                    {news?.BannerImage[0]?.ItemTitle &&
                        <ButtonLink variant="link-primary" manualLink={`${Routes.MediaPage}${news.ShowOnNewsLetterPage ? Routes.NewsPage: Routes.BlogPage}/${news?.Slug}`}>
                            <ModuleTitle
                                as="h3"
                                title={news.BannerImage[0].ItemTitle}
                                className="news-card__title"
                            />
                        </ButtonLink>
                    }
                </Stack>
            </div>
        </div>
    )
}

export default NewsCard