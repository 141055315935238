import React, { useState, useEffect } from "react";
import { delayItemsFromBottom } from '@components/utils/animations';
import { Button } from "react-bootstrap";
import { StarberryIcons } from "@components/icons/index";
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";
import { cleanP } from '@components/utils/CleanedHtml';
import { StaticImage } from "gatsby-plugin-image";
import loadable from "@loadable/component";
// import Lightbox from '@components/lightbox';
import './personGallery.scss';
const Lightbox = loadable(() => import("@components/lightbox"));

const Gallery = ({ data }) => {
    // GGFX
    let processedImages = JSON.stringify({});
    if (data?.imagetransforms?.Image_Transforms) {
        processedImages = data?.imagetransforms?.Image_Transforms;
    }
    const [renderComponent, setRenderComponent] = useState(false);
    useEffect(() => {
      window.addEventListener("mousemove", () => {
        if (renderComponent === false) {
          setRenderComponent(true)
        }
        })
        window.addEventListener("touchmove", () => {
            if (renderComponent === false) {
            setRenderComponent(true)
            }
        })
        window.addEventListener("keypress", () => {
            if (renderComponent === false) {
            setRenderComponent(true)
            }
        })
    }, [])

    return(
        <div className="person-gallery">
          
                {data?.map((person, i) => {
                    if (i === 5) {
                        return(
                            <div key="adadada322sdf23" className={`person-gallery__item person-gallery__item__full`} variants={delayItemsFromBottom} custom={i}>
                                <div className="person-gallery__image">
                                    <StaticImage
                                        src="../../../images/Blue_Trans.jpg"
                                        width={255}
                                        height={235}
                                        formats={["auto", "webp"]}
                                        alt="Image - Exclusivelinks"
                                        loading="lazy"
                                    />
                                </div>
                                <div className="person-gallery__video">
                                    {renderComponent && 
                                    <Lightbox videoUrl={["https://youtu.be/RwzaG-uoVzs"]} lightboxType="youtube" hideVideoIcon={true}>
                                        <Button variant="secondary"><StarberryIcons iconName="videoIcon" className="bi" />Our Story</Button>
                                    </Lightbox>
                                    }
                                </div>
                            </div>
                        )
                    } else {
                        return(
                            
                            <div key={person.id} className={`person-gallery__item`} variants={delayItemsFromBottom} custom={i}>
                                {/* {renderComponent &&  */}
                                    <div className="person-gallery__image">
                                        {person?.Image?.url ?
                                        
                                            <ImageTransform
                                                imagesources={person?.Image?.url}
                                                renderer="srcSet"
                                                imagename="team.Image.avatarImage"
                                                attr={{ alt: cleanP(person?.Name) + ' -  Exclusivelinks' }}
                                                imagetransformresult={processedImages}
                                                id={person.id}
                                            />
                                            // <img 
                                            // src={person?.Image?.url}
                                            // alt={cleanP(person?.Name) + ' -  Exclusivelinks'}
                                            // />
                                        :
                                            <StaticImage
                                                src="../../../images/no-image.png"
                                                width={130}
                                                height={120}
                                                formats={["auto", "webp"]}
                                                alt="Specialising In Dubai Properties -  Exclusivelinks"
                                                loading="lazy"
                                            />
                                        }
                                    </div>
                                {/* } */}
                            </div>
                        )
                    }
                })}
             
        </div>
    )
}

export default Gallery