import React from "react";
// import gql from "graphql-tag";
// import { useQuery } from '@apollo/client';
import ModuleTitle from '@components/modules/Title';
import ModuleText from '@components/modules/Text';
import Loader from '@components/loader';
import ButtonLink from '@components/ButtonLink';
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
import { fadeIn } from '@components/utils/animations';

const Link = ({ url, label }) => (
    <ButtonLink manualLink={url} variant="none">{label}</ButtonLink>
)

// const GET_ALL_PROPERTIES = gql`
//     query GetProperties {
//         sales: properties(where: {publish: true, search_type: "sales"}, limit: 0) {
//             id
//         }
//         lettings: properties(where: {publish: true, search_type: "lettings"}, limit: 0) {
//             id
//         }
//         offplan: properties(where: {publish: true, search_type: "offplan"}, limit: 0) {
//             id
//         }
//     }
// `;

const SelectText = ({ name, title, text }) => {
    // const { loading, error, data } = useQuery(GET_ALL_PROPERTIES);
    var { pathname } = useLocation()
    //console.log("name",name)
    const data = useStaticQuery(graphql`
    query {
        glstrapi {
            sales : propertiesConnection(where: {publish: true, search_type: "sales", department: "residential"}) {
                aggregate {
                  count
                }
            }
            lettings : propertiesConnection(where: {publish: true, search_type: "lettings", department: "residential"}) {
                aggregate {
                  count
                }
            }
            offplan : propertiesConnection(where: {publish: true, search_type: "sales", department: "new_developments"}) {
                aggregate {
                  count
                }
            }
            offplanprojects : propertiesConnection(where: {publish: true, search_type: "offplan", department: "new_development_projects"}) {
                aggregate {
                  count
                }
            }
          }
    }
    `)    
    switch (name) {
        case "Featured_properties":
            return(
                <Loader loading={false} error={false}>
                    <h2 className="mb-16 mb-md-24 mb-lg-32 property-slider__title" variants={fadeIn}>
                        Currently listing <strong>{data?.glstrapi?.sales?.aggregate?.count} properties for sale</strong> and <strong>{data?.glstrapi?.lettings?.aggregate?.count} properties for rent</strong>.
                    </h2>
                    <div className="property-slider__text d-block mb-40 mb-lg-56" variants={fadeIn}>
                        <p>Browse all of our <Link url="/properties/for-sale/in-dubai/" label="property for sale in Dubai" /> or <Link url="/properties/for-rent/in-dubai/" label="property for rent in Dubai" /> or <Link url="/off-plan-properties/for-sale/in-dubai/" label="off plan properties in Dubai" />.</p>
                    </div>
                </Loader>
            )
        case "Sales_Properties":
            return(
                <Loader loading={false} error={false}>
                    <h2 className="mb-16 mb-md-24 mb-lg-32 property-slider__title" variants={fadeIn}>
                        Currently listing <strong>{data?.glstrapi?.sales?.aggregate?.count} properties for sale</strong> in Dubai.
                    </h2>
                    <div className="property-slider__text d-block mb-40 mb-lg-56" variants={fadeIn}>
                        <p>Browse all of our <Link url="/properties/for-sale/in-dubai/" label="property for sale in Dubai" /> or <Link url="/properties/for-rent/in-dubai/" label="property for rent in Dubai" />.</p>
                    </div>
                </Loader>
            )
            case "Offplan_Properties":
                return(
                    <Loader loading={false} error={false}>
                        <h2 className="mb-16 mb-md-24 mb-lg-32 property-slider__title" variants={fadeIn}>
                            Currently listing <strong>{data?.glstrapi?.offplanprojects?.aggregate?.count} Offplan projects for sale</strong> in Dubai.
                        </h2>
                        <div className="property-slider__text d-block mb-40 mb-lg-56" variants={fadeIn}>
                            <p>Browse all of our <Link url="/off-plan-projects/for-sale/in-dubai/" label="Offplan projects for sale in Dubai"/> or <Link url="/off-plan-properties/for-sale/in-dubai/" label="Offplan properties for sale in Dubai" /> .</p>
                        </div>
                    </Loader>
                )
        case "Sell_properties":
            return(
                <Loader loading={false} error={false}>
                    <h2 className="mb-16 mb-md-24 mb-lg-32 property-slider__title" variants={fadeIn}>
                        Currently listing <strong>{data?.glstrapi?.sales?.aggregate?.count} properties for sale</strong> in Dubai.
                    </h2>
                    <div className="property-slider__text d-block mb-40 mb-lg-56" variants={fadeIn}>
                        <p>Browse all of our <Link url="/properties/for-sale/in-dubai/" label="property for sale in Dubai" /> or <Link url="/properties/for-rent/in-dubai/" label="property for rent in Dubai" />.</p>
                    </div>
                </Loader>
            )
        case "Rent_Properties":
            return(
                <Loader loading={false} error={false}>
                    <h2 className="mb-16 mb-md-24 mb-lg-32 property-slider__title" variants={fadeIn}>
                        Currently listing <strong>{data?.glstrapi?.lettings?.aggregate?.count} properties for rent</strong> in Dubai.
                    </h2>
                    <div className="property-slider__text d-block mb-40 mb-lg-56" variants={fadeIn}>
                        <p>Browse all of our <Link url="/properties/for-rent/in-dubai/" label="property for rent in Dubai" /> or <Link url="/properties/for-sale/in-dubai/" label="property for sale in Dubai" />.</p>
                    </div>
                </Loader>
            )
        case "Landlord_Properties":
            return(
                <Loader loading={false} error={false}>
                    <h2 className="mb-16 mb-md-24 mb-lg-32 property-slider__title" variants={fadeIn}>
                        Currently listing <strong>{data?.glstrapi?.lettings?.aggregate?.count} properties for rent</strong> in Dubai.
                    </h2>
                    <div className="property-slider__text d-block mb-40 mb-lg-56" variants={fadeIn}>
                        <p>Browse all of our <Link url="/properties/for-rent/in-dubai/" label="property for rent in Dubai" /> or <Link url="/properties/for-sale/in-dubai/" label="property for sale in Dubai" />.</p>
                    </div>
                </Loader>
            )
        case "Featured_managed_properties":
            return(
                <Loader loading={false} error={false}>
                    <h2 className="mb-16 mb-md-24 mb-lg-32 property-slider__title" variants={fadeIn}>
                    Featured <strong>Managed Properties in Dubai</strong>
                    </h2>
                    <div className="property-slider__text d-block mb-40 mb-lg-56" variants={fadeIn}>
                        <p>Browse all of our <Link url="/properties/for-sale/in-dubai/property-managed/" label="managed properties for sale in Dubai" /> or <Link url="/properties/for-rent/in-dubai/property-managed/" label="managed properties for rent in Dubai" />.</p>
                    </div>
                </Loader>
            )
            case "Elite_collection_for_buy":
                return(
                    <Loader loading={false} error={false}>
                        <h2 className="mb-16 mb-md-24 mb-lg-32 property-slider__title" variants={fadeIn}>
                        Featured <strong>Luxury Properties</strong>
                        </h2>
                        <div className="property-slider__text d-block mb-40 mb-lg-56" variants={fadeIn}>
                            <p>Exclusive Links takes pride in its position as the leading provider of luxury villas and apartments in Dubai, offering an exquisite selection of luxury properties for sale and rent that cater to the discerning tastes of affluent buyers and tenants.
Browse all of our <Link url="/properties/for-sale/in-dubai/property-elite/" label="luxury properties for sale in Dubai" /> AED15M+ or <Link url="/properties/for-rent/in-dubai/above-500000/" label="luxury properties for rent in Dubai" /> AED500K+.</p>
                        </div>
                    </Loader>
                )

            case "Elite_collection_for_sell":
                return(
                    <Loader loading={false} error={false}>
                       
                         <h2 className="mb-16 mb-md-24 mb-lg-32 property-slider__title" variants={fadeIn}>
                         Featured <strong>Luxury Properties in Dubai</strong>
                         </h2>
                        
                        <div className="property-slider__text d-block mb-40 mb-lg-56" variants={fadeIn}>
                            <p>Exclusive Links takes pride in its position as the leading provider of luxury villas and apartments in Dubai, offering an exquisite selection of luxury properties for sale and rent that cater to the discerning tastes of affluent buyers and tenants.
Browse all of our <Link url="/properties/for-sale/in-dubai/property-elite/" label="luxury properties for sale in Dubai" /> AED15M+ or <Link url="/properties/for-rent/in-dubai/above-500000/" label="luxury properties for rent in Dubai" /> AED500K+.</p>
                        </div>
                    </Loader>
                )
            case "Featured_commercial_properties" :
                return(
                    <Loader loading={false} error={false}>
                    <h2 className="mb-16 mb-md-24 mb-lg-32 property-slider__title" variants={fadeIn}>
                    Featured <strong>Commercial Properties</strong>
                    </h2>
                    <div className="property-slider__text d-block mb-40 mb-lg-56" variants={fadeIn}>
                    <p>We present a range of commercial options for sale and rent in line with government and free zone regulations.
                         Browse all of our <Link url="/commercial-properties/for-sale/in-dubai/" label="commercial properties for sale in Dubai" />  or <Link url="/commercial-properties/for-rent/in-dubai/" label="commercial properties for rent in Dubai" />.</p>
                    </div>
                </Loader>
                )
            case "Vacation_homes":
            return(
                <Loader loading={false} error={false}>
                    <h2 className="mb-16 mb-md-24 mb-lg-32 property-slider__title" variants={fadeIn}>
                    Vacation <strong>Home Properties</strong>
                    </h2>
                    <div className="property-slider__text d-block mb-40 mb-lg-56" variants={fadeIn}>
                        <p>Browse all of our <Link url="/properties/short-term-for-rent/in-dubai/" label="Vacation Homes properties to rent in Dubai" />.</p>
                    </div>
                </Loader>
            )
        default:
            return(
                <>
                    {title &&
                        <ModuleTitle
                            title={title}
                            className="mb-16 mb-md-24 mb-lg-32 property-slider__title"
                        />
                    }
                    {text &&
                        <ModuleText
                            text={text}
                            className="property-slider__text d-block mb-40 mb-lg-56"
                        />
                    }
                </>
            )
    }
}


const PropertySliderTitle = (props) => {
    return <SelectText {...props} />
}

export default PropertySliderTitle