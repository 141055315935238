import React, { useState, useEffect } from "react";
import Stack from "react-bootstrap/Stack";
import { StaticImage } from "gatsby-plugin-image";
import ModuleTitle from '@components/modules/Title';
import ModuleText from '@components/modules/Text';
import moment from 'moment';
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";
import { cleanP } from '@components/utils/CleanedHtml';

import { delayItemsFromBottom } from '@components/utils/animations';

import './award.scss';

const AwardCard = ({ award, className, custom }) => {
    const image = award?.BannerImage[0]?.Image?.url;
    const image_alt = award?.BannerImage[0]?.Image?.alternativeText ? award.BannerImage[0].Image.alternativeText : cleanP(award?.BannerImage[0]?.ItemTitle);
    // const [renderComponent, setRenderComponent] = useState(false);
    // useEffect(() => {
    //   window.addEventListener("mousemove", () => {
    //     if (renderComponent === false) {
    //       setRenderComponent(true)
    //     }
    //     })
    //     window.addEventListener("touchmove", () => {
    //         if (renderComponent === false) {
    //         setRenderComponent(true)
    //         }
    //     })
    //     window.addEventListener("keypress", () => {
    //         if (renderComponent === false) {
    //         setRenderComponent(true)
    //         }
    //     })
    // }, [])

    // GGFX
    let processedImages = JSON.stringify({});
    if (award?.imagetransforms?.BannerImage_Transforms) {
        processedImages = award?.imagetransforms?.BannerImage_Transforms;
    }
    const ImageRender = ({ alt, image_url, node, processedImages }) => {
        return (
          <ImageTransform imagesources={image_url} renderer="srcSet" imagename="awards.BannerImage.cardImgHome" attr={{ alt: alt, class: 'award-card__image mb-24 mb-md-32' }} imagetransformresult={processedImages} id={node.id} />
        )
      }
    
    return(
        <div
            className={className}
           /* variants={delayItemsFromBottom}*/
            custom={custom}
        >
            <div
                className="award-card p-24 p-md-32"
            >
                {image ?
                <ImageRender alt={image_alt} processedImages={processedImages} image_url={image} node={award} />
                    // <ImageTransform
                    //     imagesources={image}
                    //     renderer="srcSet"
                    //     imagename="awards.BannerImage.cardImg"
                    //     attr={{ alt: image_alt, className: "award-card__image mb-24 mb-md-32" }}
                    //     imagetransformresult={processedImages}
                    //     id={award.id}
                    // />
                :
                    <StaticImage
                        src="../../../images/no-image.png"
                        width={300}
                        // height={80}
                        formats={["auto", "webp"]}
                        alt=""
                        className="award-card__image mb-24 mb-md-32"
                    />
                }
                <Stack direction="vertical" className="gap-12 award-card__text">
                    {award?.BannerImage[0]?.ItemText &&
                        <ModuleText
                            text={award.BannerImage[0].ItemText}
                            className="award-card__type"
                        />
                    }
                    {award?.BannerImage[0]?.ItemTitle &&
                        <ModuleTitle
                            as="h3"
                            title={award.BannerImage[0]?.ItemTitle}
                            className="award-card__title"
                        />
                    }
                    {award?.Date &&
                        <div className="award-card__date">
                            {moment(award.Date).format("YYYY")}
                        </div>
                    }   
                </Stack>
            </div>
        </div>
    )
}

export default AwardCard
