import React, { useState, useEffect } from "react";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { StarberryIcons } from "@components/icons/index";
import ModuleTitle from '@components/modules/Title';
import ModuleText from '@components/modules/Text';

import { delayItemsFromBottom } from '@components/utils/animations';
import Modal from '@components/modal';
import './podcastCard.scss';

function HtmlText(props) {
    return <span dangerouslySetInnerHTML={createHTMLMarkup(props)} className={props?.className} />;
}
function createHTMLMarkup(props) {
    return {__html: (props.html)};
}

const PodcastCard = ({ podcast, className, custom }) => {
    const [modalShow, setModalShow] = React.useState(false);
    const image = getImage(podcast?.BannerImage[0]?.Image?.url_sharp);
    const image_alt = podcast?.BannerImage[0]?.Image?.alternativeText?podcast?.BannerImage[0]?.Image?.alternativeText  :podcast.Episode;
    // const [renderComponent, setRenderComponent] = useState(false);
    // useEffect(() => {
    //   window.addEventListener("mousemove", () => {
    //     if (renderComponent === false) {
    //       setRenderComponent(true)
    //     }
    //     })
    //     window.addEventListener("touchmove", () => {
    //         if (renderComponent === false) {
    //         setRenderComponent(true)
    //         }
    //     })
    //     window.addEventListener("keypress", () => {
    //         if (renderComponent === false) {
    //         setRenderComponent(true)
    //         }
    //     })
    // }, [])

    return(
        <div
            className={className}
            variants={delayItemsFromBottom}
            custom={custom}
        >
            <div
                className="podcast-card"
            >
                <Button variant="none" className="d-block p-0" onClick={() => setModalShow(true)}>
                    <div className="podcast-card__image__wrapper">
                        {image ?
                            <GatsbyImage quality={100} style={{height:180}} image={image} alt={image_alt ? image_alt + " - Exclusive Links" : ''} loading="lazy" className="podcast-card__image" />
                        :
                            <StaticImage
                                src="../../../images/no-image.png"
                                width={360}
                                height={180}
                                quality={100}
                                layout="constrained"
                                className="podcast-card__image"
                                alt={`${image_alt ? image_alt + ' -  Exclusive Links' : ''}`}
                            />
                        }
                        <div className="video-wrapper">
                            <StarberryIcons iconName="videoIcon" className="bi video-icon" />
                        </div>
                    </div>

                    <Stack direction="vertical" className="gap-12 p-24 p-md-32 podcast-card__text">
                        {podcast?.Episode &&
                            <ModuleText
                                text={podcast.Episode}
                                className="podcast-card__type"
                            />
                        }
                        {podcast?.Title &&
                            <ModuleTitle
                                as="h3"
                                title={podcast.Title}
                                className="podcast-card__title"
                            />
                        }
                    </Stack>
                </Button>
                {podcast?.Buzzsprout &&
                    <Modal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        title={podcast?.Title}
                        className="reduced-heading-modal"
                    >
                        <HtmlText html={podcast.Buzzsprout} />
                    </Modal>
                }
            </div>
        </div>
    )
}

export default PodcastCard