import React, { useState, useEffect } from "react";
import axios from "axios";
import { graphql } from 'gatsby';
import ModuleSection from '@components/modules/moduleWrapper';
import { SwiperSlide } from 'swiper/react';
import Carousel from '@components/carousel';
import PropertyCard from '@components/property/PropertyCard';
import PropertySliderTitle from '@components/modules/PropertySlider/title';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import { fadeIn } from '@components/utils/animations';
import ButtonLink from '@components/ButtonLink';
import './propertySlider.scss';
// import { getDataFromTree } from "@apollo/client/react/ssr";
const Link = ({ url, label }) => (
    <ButtonLink manualLink={url} variant="none">{label}</ButtonLink>
)

const PropertySlider = ({ moduleData, stb_lists, teampage, CountryFlagStatus, projectpage, Email, area, areaguidepage, slug, area_guide, setproperty }) => {
    //console.log("propertyslider",stb_lists)

    const [propItems, setPropItems] = useState([])
    const [teamItems, setTeamItems] = useState([])
    const [projectItems, setProjectItems] = useState([])
    const [projectItemss, setProjectItemss] = useState([])
    const [projectItemsl, setProjectItemsl] = useState([])
    const [areaguideItems, setareaguideItems] = useState([])
    const [areaguideItemss, setareaguideItemss] = useState([])
    const [areaguideItemsl, setareaguideItemsl] = useState([])
  

    useEffect(() => {
        var data = []
        areaguideItemss?.map(item => {
            data.push(item)
        })

        areaguideItemsl?.map(item => {
            data.push(item)
        })

        setareaguideItems(data)
    }, [areaguideItemsl, areaguideItemss])

    useEffect(() => {
        var datap = []
        projectItemss?.map(item => {
            datap.push(item)
        })

        projectItemsl?.map(item => {
            datap.push(item)
        })

        setProjectItems(datap)
    }, [projectItemsl, projectItemss])


    const getitems = async url => {
        try {
            const { data } = await axios.get(url, {
                headers: {
                Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
                }})
            // console.log("PropertySimilar", data)
            setPropItems(data)
        } catch (error) {
            console.error(error)
        }
    }
    const getitemst = async url => {
        try {
            const { data } = await axios.get(url, {
                headers: {
                Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
                }})
            // console.log("PropertySimilar", data)
            setTeamItems(data)
        } catch (error) {
            console.error(error)
        }
    }
    const getitemsp = async url => {
        try {
            const { data } = await axios.get(url, {
                headers: {
                Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
                }})
            // console.log("PropertySimilar", data)
            setProjectItemss(data)
            let projecturll = process.env.GATSBY_STRAPI_SRC + "/properties?_limit=5&_sort=price:DESC&publish=true&search_type=lettings";
            projecturll = projecturll + "&display_address_contains=" + area            
            getitemspl(projecturll)
        } catch (error) {
            console.error(error)
        }
    }
    const getitemspl = async url => {
        try {
            const { data } = await axios.get(url, {
                headers: {
                Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
                }})
            // console.log("PropertySimilar", data)
            setProjectItemsl(data)
        } catch (error) {
            console.error(error)
        }
    }
    const getitemsas = async url => {
        try {
            const { data } = await axios.get(url, {
                headers: {
                Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
                }})
            // console.log("PropertySimilar", data)
            setareaguideItemss(data)
            let areaguideurll = process.env.GATSBY_STRAPI_SRC + "/properties?_limit=5&_sort=price:DESC&publish=true&search_type=lettings";
            areaguideurll = areaguideurll + "&display_address_contains=" + area
            getitemsal(areaguideurll)
            setproperty(data?.length > 0 ? true : false)
        } catch (error) {
            console.error(error)
        }
    }
    const getitemsal = async url => {
        try {
            const { data } = await axios.get(url, {
                headers: {
                Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
                }})
            // console.log("PropertySimilar", data)
            setareaguideItemsl(data)
           
            setproperty(data?.length > 0 ? true : false)
        } catch (error) {
            console.error(error)
        }
    }
    useEffect(() => {
        if (teampage) {
            let teamurl = process.env.GATSBY_STRAPI_SRC + "/properties?crm_negotiator_email=" + Email + "&_sort=price:DESC&publish=true";
            getitemst(teamurl)
        }

    }, [teampage]);

    useEffect(() => {
        if (projectpage) {
            let projecturl = process.env.GATSBY_STRAPI_SRC + "/properties?_limit=5&_sort=price:DESC&publish=true&search_type=sales";
            projecturl = projecturl + "&display_address_contains=" + area            
            getitemsp(projecturl)
        }
    }, [projectpage]);

    useEffect(() => {
        if (areaguidepage) {
            let areaguideurls = process.env.GATSBY_STRAPI_SRC + "/properties?_limit=5&_sort=price:DESC&publish=true&search_type=sales";
            areaguideurls = areaguideurls + "&display_address_contains=" + area
            getitemsas(areaguideurls)
          
        }
    }, [areaguidepage]);


    useEffect(() => {
        let url =
            process.env.GATSBY_STRAPI_SRC +
            `/stb-lists/item/${stb_lists?.Name}`

        getitems(url)
    }, [stb_lists?.Name]);

    const sliderConfig = {
        slidesPerView: 1,
        spaceBetween: 32,
        navigation: CountryFlagStatus ? false : true,
        autoplay: true,
        // loop:true,
        breakpoints: {
            // when window width is >= 768px
            768: {
                slidesPerView: 2,
                spaceBetween: 16
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 32
            },
            1200: {
                slidesPerView: stb_lists?.Name === "feature_international_properties" ? 3 : 3,
                spaceBetween: 32
            }
        }
    }


    const salesList = teamItems.filter(element => element.search_type === "sales").length;
    const rentList = teamItems.filter(element => element.search_type === "lettings").length;

 
    return (
        teamItems?.length > 0 || projectItems?.length > 0 || propItems?.length > 0 || areaguideItems?.length > 0 ?
        <ModuleSection
            id="properties"
            removeContainer={true}
            sectionClass={`section-grey property-slider swiper-overflow-hidden ${moduleData?.moduleClass ? ` ${moduleData.moduleClass}` : ''} ${CountryFlagStatus ? "overflow-hidden" : ""}`}
            sectionSpacing={moduleData?.sectionSpacing}
        >
            <Container fluid="lg">
                <Row>
                    <Col xs={12}>
                        {areaguidepage ?
                            <>
                                <h2 className="mb-16 mb-md-24 mb-lg-32 property-slider__title" /*variants={fadeIn}*/>
                                    <strong>Featured Properties in {area}</strong>
                                </h2>
                                <div className="property-slider__text d-block mb-40 mb-lg-56" /*variants={fadeIn}*/>
                                    <p>Browse all <Link url={`/properties/for-sale/in-${slug?.replace("-area-guide", "")}/`} label={`properties to buy in ${area}`} /> or <Link url={`/properties/for-rent/in-${slug?.replace("-area-guide", "")}/`} label={`properties for rent in ${area}`} />.</p>
                                </div>
                            </>
                            :
                            projectpage ?
                                <>
                                    <h2 className="mb-16 mb-md-24 mb-lg-32 property-slider__title" /*variants={fadeIn}*/>
                                        <strong>Featured Properties in {area}, {area_guide?.Pagename}</strong>
                                    </h2>
                                    <div className="property-slider__text d-block mb-40 mb-lg-56" /*variants={fadeIn}*/>
                                        <p>Browse all <Link url={`/properties/for-sale/in-${area_guide?.Slug?.replace("-area-guide", "")}/`} label={`properties to buy in ${area_guide?.Pagename}`} /> or <Link url={`/properties/for-rent/in-${area_guide?.Slug?.replace("-area-guide", "")}/`} label={`properties for rent in ${area_guide?.Pagename}`} />.</p>
                                    </div>
                                </>
                                :
                                (teampage && (salesList > 0 || rentList > 0))?
                                <>
                                    <h2 className="mb-16 mb-md-24 mb-lg-32 property-slider__title" /*variants={fadeIn}*/>
                                        <strong>My Properties Listing</strong>
                                    </h2>
                                    <div className="property-slider__text d-block mb-40 mb-lg-56" /*variants={fadeIn}*/>
                                        <p>Browse all of my 
                                            {salesList > 0 &&
                                            <>&nbsp;<Link url={`/properties/for-sale/negotiator-${Email}/`} label={`properties to buy Dubai`} /></> 
                                            }
                                             {rentList > 0 &&
                                             <>
                                             {salesList > 0 ? ' or' : ''}
                                             &nbsp;<Link url={`/properties/for-rent/negotiator-${Email}/`} label={`properties to lease in Dubai`} /> 
                                             </> 
                                            }.</p>

                                    </div>
                                </>
                                :
                                <PropertySliderTitle
                                    name={CountryFlagStatus ? "" : stb_lists?.Name}
                                    title={moduleData?.Title}
                                    text={moduleData?.Text}
                                />
                        }
                    </Col>
                </Row>
            </Container>
            <Container fluid={stb_lists?.Name === "feature_international_properties" ? "lg" : "lg"}>
                <Row>
                    <Col xs={12}>
                        {teampage ?
                            <Carousel className="properties-carousel" {...sliderConfig}>
                                {teamItems?.map((property, i) => {
                                    return (
                                        <SwiperSlide key={property.id}>
                                            <PropertyCard property={property} className="properties-carousel__item" custom={i} />
                                        </SwiperSlide>
                                    )
                                })}
                            </Carousel>
                            : projectpage ?
                                <Carousel className="properties-carousel" {...sliderConfig}>
                                    {projectItems?.map((property, i) => {
                                        return (
                                            <SwiperSlide key={property.id}>
                                                <PropertyCard property={property} className="properties-carousel__item" custom={i} />
                                            </SwiperSlide>
                                        )
                                    })}
                                </Carousel>
                                : areaguidepage ?
                                    <Carousel className="properties-carousel" {...sliderConfig}>
                                        {areaguideItems?.map((property, i) => {
                                            return (
                                                <SwiperSlide key={property.id}>
                                                    <PropertyCard property={property} className="properties-carousel__item" custom={i} />
                                                </SwiperSlide>
                                            )
                                        })}
                                    </Carousel>
                                    :
                                    <Carousel className={`properties-carousel ${CountryFlagStatus && "country-page-featured-properties"} `} {...sliderConfig}>
                                        {propItems?.map((property, i) => {
                                            return (
                                                <SwiperSlide key={property.id}>
                                                    <PropertyCard international={stb_lists?.Name === "feature_international_properties" ? true : false} property={property} className="properties-carousel__item" custom={i} />
                                                </SwiperSlide>
                                            )
                                        })}
                                    </Carousel>
                        }
                    </Col>
                </Row>
            </Container>
            {/* {JSON.stringify(areaguideItems)} */}
        </ModuleSection>
        
        : null
        )
}

export default PropertySlider

export const propertySliderFragment = graphql`
    fragment PropertySliderFragment on GLSTRAPI_ComponentModulesPropertySlider {
        id
        moduleType
        stb_lists {
            id
            Name
        }
        moduleData {
            Text
            Title
            moduleClass
            sectionSpacing
        }
    }
`