import React from 'react';
// import { useQuery } from '@apollo/client';
import { useStaticQuery, graphql } from "gatsby"
import ModuleSection from '@components/modules/moduleWrapper';
import ModuleTitle from '@components/modules/Title';
import ModuleText from '@components/modules/Text';
import { Col, Row, Stack } from "react-bootstrap";
import ButtonLink from '@components/ButtonLink';
import CountNumber from '@components/countNumber';
import Gallery from './personGallery';
// import { contentItemStagger, contentItem } from '@components/utils/animations';
import Loader from '@components/loader';
import {sampleSize} from "lodash";

import './aboutPeople.scss';


// const GET_ALL_TEAMS = gql`
//     query GetTeams {
//         teams(publicationState: LIVE, sort: "Order:DESC") {
//             Name
//             Slug
//             id
//             JobTitle
//             Phone
//             WhatsApp
//             Email
//             Order
//             Department
//             imagetransforms
//             Image {
//                 url
//             }
//         }
//     }
// `;

const AboutPeople = ({ moduleData, Button, HomepageCounter }) => {
    // const { loading, error, data } = useQuery(GET_ALL_TEAMS);
    const data = useStaticQuery(graphql`
    query {
      glstrapi {
        teams(where: {IsActive: true}, publicationState: LIVE, sort: "Order:DESC") {
            Name
            Slug
            id
            JobTitle
            Phone
            WhatsApp
            Email
            Order
            IsActive
            Department
            imagetransforms
            Image {
                url
            }
        }
      }
    }
    `)
    let rndTeamArr = sampleSize(data?.glstrapi?.teams, 13);

    return(
        <ModuleSection
            sectionClass={`about-people ${moduleData?.moduleClass ? moduleData.moduleClass : ""}`}
            removeContainer={moduleData?.removeContainer ? moduleData.removeContainer : false}
            sectionSpacing={moduleData?.sectionSpacing}
        >
            <Row>
                <Col lg={6} /*variants={contentItemStagger}*/ >
                    {moduleData?.Title &&
                        <div /*variants={contentItem}*/>
                            <ModuleTitle
                                title={moduleData.Title}
                                className="about-people__title mb-16 mb-md-24 mb-lg-32"
                            />
                        </div>
                    }
                    {moduleData?.Text &&
                        <div /*variants={contentItem}*/>
                            <ModuleText
                                text={moduleData.Text}
                                className="about-people__text mb-24 mb-md-32 mb-lg-40"
                            />
                        </div>
                    }
                    {Button.length > 0 &&
                        <Stack direction="horizontal" className="gap-16 mb-40 mb-md-48 mb-lg-80" /*variants={contentItem}*/>
                            {Button.map((btn) => {
                                return(
                                    <ButtonLink key={btn?.id} {...btn} variant="link-primary-underlined" />
                                )
                            })}
                        </Stack>
                    }
                    <div className="row row-cols-2 row-cols-md-3 gx-16 gy-32 gy-md-0 mb-40 mb-md-56 mb-lg-0">
                        {HomepageCounter && HomepageCounter.length > 0 && HomepageCounter.map((count)=>{
                                return(
                                    <Col>
                                        <CountNumber
                                                from={parseInt(count?.Count_From)}
                                                to={parseInt(count?.Count_To)}
                                                suffix={count?.Suffix}
                                                text={count?.Review_Text}
                                        />
                                    </Col>
                                )
                        })}
                    </div>
                </Col>
                <Col lg={{ span: 5, offset: 1 }}>
                    <Loader loading={false} error={false} hideText={true}>
                        <Gallery data={rndTeamArr} />
                    </Loader>
                </Col>
            </Row>
        </ModuleSection>
    )
}

export default AboutPeople

export const aboutPeopleFragment = graphql`
    fragment AboutPeopleFragment on GLSTRAPI_ComponentModulesAboutPeople {
        id
        moduleType
        moduleData {
            Text
            Title
            moduleClass
            sectionSpacing
        }
        Button {
            id
            manualLink
            btnClass
            label
            icon
            page {
                Slug
                id
                Parent {
                    Slug
                }
                Parent2 {
                    Slug
                }
                Parent3 {
                    Slug
                }
            }
        }
        HomepageCounter {
            Count_From
            Count_To
            Suffix
            Review_Text
        }
    }
`